import React from "react";
import { Link } from "gatsby";
import Layout from "../Components/Layout";
import { ArrowAtgal } from "../Components/Buttons";

function Privatumas() {
  return (
    <Layout>
    <div className="Rules">
      <div className="rulesNav">
        <Link to="/" aria-label="Grįžti atgal į pagrindinį puslapį">
          <ArrowAtgal>GRĮŽTI Į PAGRINDINĮ PUSLAPĮ</ArrowAtgal>
        </Link>
      </div>
      <div className="rulesMain">
        <h3>PRIVATUMO NUOSTATOS</h3>
        
          <p className="questions">
            Europos egzamino dalyvių privatumo politika
          </p>
          <p id="body">
          Europos egzamino organizatoriai – Europos Komisijos atstovybė Lietuvoje (toliau – EKA Lietuvoje) – rūpinasi Jūsų informacijos apsauga. EKA Lietuvoje laikosi Europos Sąjungos Bendrojo duomenų apsaugos reglamento (BDAR) bei kitų įstatymų, saugančių Jūsų informaciją, kuri yra renkama, naudojama ir saugoma EKA Lietuvoje. 
          </p>
          <p className="questions">
          Kaip turėčiau skaityti šią privatumo politiką?
          </p>
          <p id="body">
          Šioje Privatumo politikoje bus atsakyta į svarbiausius klausimus apie tai, kaip mes renkame, naudojame ir saugome informaciją apie Jus. Jei turite kokių nors klausimų arba bet kuri šios privatumo politikos dalis yra neaiški, mes esame pasirengę Jums padėti, kaip aprašyta šioje Privatumo politikoje. Prašome atkreipti dėmesį, kad bet kokia žodžio „mes“ forma šioje Privatumo politikoje reiškia Europos Komisijos atstovybę Lietuvoje. 
          </p>
          <p className="questions">
          Kas yra atsakingas už mano informacijos apsaugą? 
          </p>
          <p id="body">
          Duomenų valdytojas: <br />
          Europos Komisijos atstovybė Lietuvoje <br />
          Gedimino pr. 16, Vilnius <br /> <br/>
          Duomenų tvarkytojai: <br />
          Europos Komisijos atstovybė Lietuvoje, Gedimino pr. 16, Vilnius, <br /><br />
          UAB „Integrity PR“, šv. Ignoto g. 5, Vilnius<br /> <br />
          Viešoji įstaiga „Lietuvos nacionalinis radijas ir televizija“, Konarskio g. 49, Vilnius.
          </p>
          <p className="questions">
          Kodėl renkate informaciją apie mane?
          </p>
          <p id="body">
          Organizuojame Europos egzamino viktoriną, kurioje privaloma registracija tam, kad galėtume sekti dalyvių rezultatus ir apie juos informuoti bei susisiekti, jei kyla problemų arba klausimų.
          <br /> <br />
          Jūsų pateikti duomenys nebus naudojami kitais tikslais, nei nurodyta pirmiau.
          <br /> <br />
          Asmuo, nepateikęs registracijai reikalingų duomenų, Europos egzamine dalyvauti negalės.

          </p>
          <p className="questions">
          Kokią informaciją apie mane renkate, naudojate ir saugote? 
          </p>
          <p id="body">
          &emsp;&#x25CF;&ensp; dalyvio vardas, pavardė;<br />
          &emsp;&#x25CF;&ensp; atstovaujama organizacija (mokykla ar kt.);<br />
          &emsp;&#x25CF;&ensp; el. paštas. 
          </p>
          <p className="questions">
          Koks yra teisinis informacijos apie mane rinkimo pagrindas?
          </p>
          <p id="body">
          Mes renkame informaciją apie Jus teisėtai, nes užsiregistruodami dalyvauti Europos egzamine Jūs sudarote su mumis sutartį (BDAR 6 str. 1 d. b punktas).
          </p>
          <p className="questions">
          Ar Jūs renkate jautrią informaciją apie mane?
          </p>
          <p id="body">
          Jautri informacija apie Jus nėra renkama ir prašome mums tokios informacijos neteikti. Visi gauti jautrūs duomenys bus nedelsiant ištrinti.
          </p>
          <p className="questions">
          Ar vykdote automatinį sprendimų priėmimą ar profiliavimą pagal informaciją apie mane?
          </p>
          <p id="body">
          Automatinis sprendimų priėmimas nėra vykdomas.<br /><br />
          Renkami duomenys yra profiliuojami į mokytojų ir pavienių asmenų kontaktus.
          </p>
          <p className="questions">
          Ar dalinatės mano informacija su kitais subjektais?
          </p>
          <p id="body">
          Informacija dalinamės su išorės paslaugų teikėjais ta apimtimi, kuri būtina užtikrinant šių trečiųjų šalių paslaugų suteikimą:<br /><br />
          teikėjas viešoji įstaiga „Lietuvos nacionalinis radijas ir televizija“ vykdo duomenų tvarkytojo funkcijas kaip interneto svetainės palaikymo paslaugas teikiantis juridinis asmuo. Jūsų informacija su subjektais, esančiais už Europos ekonominės erdvės ribų, nesidaliname.  
          </p>
          <p className="questions">
          Kiek laiko saugote informaciją apie mane?
          </p>
          <p id="body">
          Jūsų informaciją saugosime vienerius metus nuo jos gavimo. 
          </p>
          <p className="questions">
          Kokių veiksmų galiu imtis dėl savo informacijos?
          </p>
          <p id="body">
          Jei norite imtis žemiau nurodytų veiksmų, prašome kreiptis į mus toliau nurodytais kontaktais:<br /><br />
          &emsp;&#x25CF;&ensp;pateikti prašymą atskleisti apie Jus turimą informaciją;<br /><br />
          &emsp;&#x25CF;&ensp;pateikti prašymą ištaisyti turimą informaciją apie Jus;<br /><br />
          &emsp;&#x25CF;&ensp;pateikti prašymą ištrinti turimą informaciją apie Jus;<br /><br />
          &emsp;&#x25CF;&ensp;pateikti prašymą apriboti prieigą prie turimos informacijos apie Jus ar jos ištrynimą;<br /><br />
          &emsp;&#x25CF;&ensp;ginčyti Jūsų informacijos rinkimą, naudojimą ir saugojimą mūsų bendrovėje;<br /><br />
          &emsp;&#x25CF;&ensp;pateikti prašymą eksportuoti Jūsų duomenis arba perkelti juos į kitą bendrovę;<br /><br />
          &emsp;&#x25CF;&ensp;pateikti skundą Valstybinei duomenų apsaugos inspekcijai.<br /><br />
          Atkreipiame dėmesį, kad šioms Jūsų teisėms gali būti taikomos įstatymų numatytos išimtys ir ribojimai. 
          </p>
          <p className="questions">
          Kaip galite man padėti?
          </p>
          <p id="body">
          Jeigu turite klausimų, komentarų ar nusiskundimų, susijusių su tuo, kaip mes renkame, naudojame ir saugome duomenis apie Jus, esame pasirengę Jums padėti. Jei Jums reikalinga pagalba, prašome kreiptis el. paštu <a href="mailto:comm-rep-lithuania@ec.europa.eu">comm-rep-lithuania@ec.europa.eu</a>. 
          </p>
      </div>
    </div>
    </Layout>
  );
}

export default Privatumas;